import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Container.module.scss';

const Container = ({ className, children }) => {
    const rootClass = classNames(styles.root, className);

    return <div className={rootClass}>{children}</div>;
};

Container.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

Container.defaultProps = {
    className: null,
};

export default Container;
