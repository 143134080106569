import React, { memo } from 'react';

import Container from 'app/components/Container';

import BannerLogo from './BannerLogo';

import styles from './Banner.module.scss';

const Banner = () => (
    <header className={styles.root}>
        <Container className={styles.container}>
            <BannerLogo />
        </Container>
    </header>
);

export default memo(Banner);
