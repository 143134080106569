import React, { memo } from 'react';

import Link from 'app/components/Link';
import Container from 'app/components/Container';

import styles from './ContentInfo.module.scss';

const ContentInfo = () => (
    <footer className={styles.root}>
        <Container>
            <div className={styles.inner}>
                <div className={styles.links}>
                    <a href="mailto:dave@wotnomushypeas.co.uk">Curated by Dave Carter</a>
                </div>
                <div className={styles.links}>
                    <Link to="/legal/privacy">Privacy & Cookies</Link>
                    <Link to="/legal/disclaimer">Disclaimer</Link>
                </div>
            </div>
        </Container>
    </footer>
);

export default memo(ContentInfo);
