import React from 'react';

import Link from 'app/components/Link';

import styles from './BannerLogo.module.scss';

const BannerLogo = () => (
    <Link className={styles.root} to="/">
        <div className={styles.icon} />
        Wot No Mushy Peas
    </Link>
);

export default BannerLogo;
