import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';

const Link = ({ to, isNewWindow, children, ...otherProps }) => {
    const newWindowParams = isNewWindow ? { target: '_blank', rel: 'noopener noreferrer' } : {};

    if (
        to.match(/^http/u) ||
        to.match(/^mailto:/u) ||
        to.match(/^tel:/u) ||
        to.match(/^\$\{[_A-Z0-9]+\}/u)
    ) {
        return (
            <a href={to} {...otherProps} {...newWindowParams}>
                {children}
            </a>
        );
    }

    return (
        <GatsbyLink to={to} {...otherProps} {...newWindowParams}>
            {children}
        </GatsbyLink>
    );
};

Link.propTypes = {
    children: PropTypes.node.isRequired,
    to: PropTypes.string.isRequired,
    className: PropTypes.string,
    isNewWindow: PropTypes.bool,
    onClick: PropTypes.func,
};

Link.defaultProps = {
    isNewWindow: false,
    className: null,
    onClick: () => null,
};

export default Link;
