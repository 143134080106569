/* eslint-disable max-len */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Banner from 'app/components/Banner';
import ContentInfo from 'app/components/ContentInfo';

import 'app/utility/styles/elements.scss';
import 'app/utility/styles/layout.scss';

import styles from './Scene.module.scss';

const Scene = ({ title, meta, children }) => (
    <Fragment>
        <Helmet>
            <title>{title ? `${title} | ` : ''}Wot No Mushy Peas</title>
            <meta name="description" content={meta} />
        </Helmet>
        <div className={styles.root}>
            <div className={styles.content}>
                <Banner />
                {children}
            </div>
            <ContentInfo />
        </div>
    </Fragment>
);

Scene.propTypes = {
    children: PropTypes.node.isRequired,
    meta: PropTypes.string,
    title: PropTypes.string,
};

Scene.defaultProps = {
    title: null,
    meta:
        'Fixture archive for Gainsborough Trinity FC, currently playing in the Northern Premier League.',
};

export default Scene;
